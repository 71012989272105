import React, { useState, useCallback } from 'react';
import { Accordion } from 'semantic-ui-react';

import Layout from '../components/layout';
import SEO from '../components/seo';
import styled from 'styled-components';

const rows = [{
  title: 'Detailed description of goods and/or services',
  body: 'foongit (Pty) Ltd is a business in the financial service industry that provides an escrow service.'
}, {
  title: 'Delivery policy',
  body: 'foongit (Pty) Ltd has no delivery policy as there are no goods which are sold to client(s) by foongit.'
}, {
  title: 'Export restriction',
  body: 'The foongit platform currently only offers its services to South African users.',
}, {
  title: 'Refunds policy',
  body: 'In the case of any inability experienced by foongit (PTY) Ltd in providing a service, foongit (Pty) Ltd will refund the initial money deposited and fees charged to client(s). Note that interest earned on the money deposited will accrue to foongit (Pty) Ltd.',
}, {
  title: 'Customer Privacy policy',
  body: 'foongit (Pty) Ltd shall take any and all reasonable steps to protect the personal information of all users. For the purpose of this clause, "personal information" is defined in Section 1 of the Promotion of Access to Information Act 2 of 2000 ("PAIA"). The PAIA may be downloaded from: http://www.polity.org.za/attachment.php?aa_id=3569',
}, {
  title: 'Payment options accepted',
  body: 'Payment may be made via Visa, MasterCard, Diners or American Express Cards or by bank transfer into the foongit (Pty) Ltd bank account through Ozow, the details of which will be provided on request.'
}, {
  title: 'Card acquiring and security',
  body: 'All card transactions for foongit (Pty) Ltd will be acquired via PayGate (Pty) Ltd, an approved payment gateway for all South African Acquiring Banks. DPO PayGate uses the strictest form of encryption, namely Secure Socket Layer 3 (SSL3) and no Card details are stored on the website. Users may go to www.paygate.co.za to view their security certificate and security policy.',
}, {
  title: 'Customer details separate from card details',
  body: "Customer details will be stored by foongit (Pty) Ltd separately from card details which are entered by the client on DPO PayGate's secure site. For more detail on DPO PayGate refer to www.paygate.co.za.",
}, {
  title: 'Merchant Outlet country and transaction currency',
  body: 'The merchant outlet country at the time of presenting payment options to the cardholder is South Africa. Transaction currency is South African Rand (ZAR).',
}, {
  title: 'Responsibility',
  body: 'foongit (Pty) Ltd takes responsibility for all aspects relating to the transaction including customer service and support and dispute initiation.',
}, {
  title: 'Country of domicile',
  body: 'This website is governed by the laws of South Africa and foongit (Pty) Ltd chooses as its domicilium citandi et executandi for all purposes under this agreement, whether in respect of court process, notice, or other documents or communication of whatsoever nature. Address: 3 Fir Street, Mayberry Park, Johannesburg, South Africa',
}, {
  title: 'Variation',
  body: 'foongit (Pty) Ltd may, in its sole discretion, change this agreement or any part therof at any time without notice.',
}, {
  title: 'Company information',
  body: 'This website is run by foongit (Pty) Ltd based in South Africa trading as foongit (Pty) Ltd and with registration number K2020270477 and 3 Directors and Co-Founders.',
}, {
  title: 'foongit (Pty) Ltd contact details',
  body: 'Company Physical Address: 3 Fir Street Johannesburg Mayberry Park Alberton 1448 - Email: hello@foongit.com',
}];

const TermsAndConditions = ({ className }) => {
  const [activeAccordionIndex, setActiveAccordionIndex] = useState(-1);

  const onAccordionClick = useCallback((_e, titleProps) => {
    const { index, active } = titleProps;

    setActiveAccordionIndex(active ? -1 : index);
  }, [activeAccordionIndex]);

  return (
    <Layout>
      <SEO title="Terms & Conditions" />
      <div className={className}>
        <div className="content">
          <h2>Terms &amp; Conditions</h2>
          <Accordion
            className="registrations"
            fluid
            styled
          >
            {rows.map(({ title, body }, index) => (
              <>
                <Accordion.Title
                  key={`title-${index}`}
                  active={activeAccordionIndex === index}
                  index={index}
                  onClick={onAccordionClick}
                >
                  {title}
                </Accordion.Title>
                <Accordion.Content key={`content-${index}`} active={activeAccordionIndex === index}>
                  {body}
                </Accordion.Content>
              </>
            ))}
          </Accordion>
        </div>
      </div>
    </Layout>
  );
}

export default styled(TermsAndConditions)`
  display: flex;
  align-items: center;
  justify-content: center;

  .content {
    border-radius: 10px;
    margin: 3rem;
    padding: 12px;
    width: 100%;
  }
`;
